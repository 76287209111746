import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

interface AnimationProps {
  delay?: number
  children: React.ReactNode
  offset?: number
  h100?: boolean
  className?: string
}

export const FadeIn = ({
  h100,
  children,
  className,
  ...other
}: AnimationProps) => (
  <ScrollAnimation
    animateIn="fadeIn"
    {...other}
    animateOnce
    style={{ height: h100 ? "100%" : undefined }}
    className={className}
  >
    {children}
  </ScrollAnimation>
)
