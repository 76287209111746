import React from "react"
import { Drawer, Fab, Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import CloseIcon from "@material-ui/icons/Close"
import { WebsiteHeader_allPrismicHeader_nodes_data as HeaderData } from "../../../../__generated__/WebsiteHeader"
import { useHeaderLogo } from "./hooks"
import { LinkField } from "../../../../root/common"
import { toLinkData } from "../../../../root/contents/prismic"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.common.white,

    "& .MuiDrawer-paper": {
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  logo: {
    margin: theme.spacing(2),
    width: 56,
  },
  closeFab: {
    margin: theme.spacing(1),
    backgroundColor: "transparent !important",
    color: theme.palette.common.white,
  },
  linkItem: {
    margin: theme.spacing(2, 1),
    textAlign: "center",

    "& .MuiTypography-root": {
      color: theme.palette.common.white,
      textTransform: "uppercase",
    },
  },
  active: {},
}))

interface Props {
  data: HeaderData
  open: boolean
  onClose: () => void
}

const HeaderDrawerContent = ({ data, open, onClose }: Props) => {
  const classes = useStyles()
  const logo = useHeaderLogo("transparent")
  return (
    <Drawer className={classes.root} open={open} anchor="right">
      <div className={classes.head}>
        <div>
          <img src={logo} className={classes.logo} />
        </div>
        <div>
          <Fab size="small" onClick={onClose} className={classes.closeFab}>
            <CloseIcon />
          </Fab>
        </div>
      </div>
      <div className={classes.content}>
        {data.body?.map((item, index) => (
          <LinkField
            activeClassName={classes.active}
            className={classes.linkItem}
            value={toLinkData(item?.primary?.link_target)}
            onClick={onClose}
            key={index}
          >
            <Typography variant="h5">{item?.primary?.link_title}</Typography>
          </LinkField>
        ))}
      </div>
    </Drawer>
  )
}

export default HeaderDrawerContent
