import { Grid, makeStyles, Theme } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import { LinkField } from "../../../../root/common"
import { toLinkData } from "../../../../root/contents/prismic"
import { getMuiColor } from "../../../../root/ui/mui"
import { WebsiteHeader_allPrismicHeader_nodes_data as HeaderData } from "../../../../__generated__/WebsiteHeader"
import { useHeaderLogo } from "./hooks"
import { HeaderStyle } from "./types"

interface Props {
  data: HeaderData
  style: HeaderStyle
}

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    position: ({ inline }: any) => (!inline ? "absolute" : undefined),
    top: 0,
    left: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    minHeight: theme.spacing(8),
    zIndex: 1,
    backgroundColor: ({ backgroundColor }: any) => backgroundColor,

    // [whenDesktop(theme)]: {
    //   paddingTop: ({ backgroundColor }: any) =>
    //     backgroundColor === undefined || backgroundColor === "transparent"
    //       ? theme.spacing(4)
    //       : undefined,
    // },
  },
  header: {
    padding: theme.spacing(1, 2),
  },
  linkItem: {
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "4px",
    marginRight: theme.spacing(2),
    color: ({ textColor }: any) => getMuiColor(theme, textColor),
    position: "relative",

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
    },

    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(7),
    },

    [theme.breakpoints.down("md")]: {
      letterSpacing: "3px",
    },

    "&:hover": {
      fontWeight: "bold",
    },

    "& .dash": {
      display: "none",
      position: "absolute",
      left: -12,
      bottom: 6,
      width: 8,
      height: "2px",
      borderBottom: ({ textColor }: any) =>
        `2px solid ${getMuiColor(theme, textColor)}`,
    },

    "&:hover .dash": {
      display: "block",
    },
  },
  lang: {
    marginRight: theme.spacing(4),
    // fontSize: fontSizes.subtitle2,

    "&:hover": {
      fontWeight: 300,
    },
  },
  logoItem: {
    paddingLeft: theme.spacing(10),

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(4),
    },
  },
  linksContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(8),

    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(2),
    },
  },
  links: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.common.white,
  },
  logo: {
    width: "77px",
    height: "41px",
  },
  active: {
    fontWeight: "bold",

    "& .dash": {
      display: "block",
    },
  },
}))

const HeaderBar = ({ data, style }: Props) => {
  const classes = useStyles({
    inline: style !== "transparent",
    backgroundColor: style === "transparent" ? "transparent" : undefined,
    textColor: style === "transparent" ? "white" : "primary",
  })
  const logo = useHeaderLogo(style)
  return (
    <div className={classes.appbar}>
      <Grid container>
        <Grid item xs={3} className={classes.logoItem}>
          <Link to="/">
            <img src={logo} className={classes.logo} />
          </Link>
        </Grid>
        <Grid item xs={9} className={classes.linksContainer}>
          <div className={classes.links}>
            {data.body?.map((item, index) => (
              <LinkField
                activeClassName={classes.active}
                className={classes.linkItem}
                value={toLinkData(item?.primary?.link_target)}
                key={index}
              >
                <div className="dash" />
                {item?.primary?.link_title}
              </LinkField>
            ))}
            {/* <LanguageSwitch
              component={(lang) => (
                <CustomTypography
                  variant="subtitle2"
                  weight="lighter"
                  className={classes.lang}
                >
                  {lang}
                </CustomTypography>
              )}
            /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default HeaderBar
