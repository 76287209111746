import { graphql, useStaticQuery } from "gatsby"
import { WebsiteHeader } from "../../../__generated__/WebsiteHeader"

export const useHeaderDataQuery = () =>
  useStaticQuery<WebsiteHeader>(graphql`
    query WebsiteHeader {
      allPrismicHeader {
        nodes {
          lang
          data {
            body {
              ... on PrismicHeaderBodyLink {
                id
                primary {
                  link_title
                  link_target {
                    id
                    url
                    link_type
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
