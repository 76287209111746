import { graphql, useStaticQuery } from "gatsby"
import { WebsiteFooter } from "../../../__generated__/WebsiteFooter"

export const useFooterDataQuery = () =>
  useStaticQuery<WebsiteFooter>(graphql`
    query WebsiteFooter {
      allPrismicFooter {
        nodes {
          lang
          data {
            footer_logo {
              fluid {
                ...ImageFluid
              }
            }
            footer_links {
              footer_link_title {
                text
              }
              footer_link_target {
                id
                target
                link_type
              }
            }
            footer_copyright {
              raw
            }
            company_name {
              text
            }
            company_data {
              raw
            }
            facebook_link {
              url
              type
              target
              id
            }
            instagram_link {
              url
              type
              target
              id
            }
            linkedin_link {
              url
              type
              id
              target
            }
            twitter_link {
              url
              target
              type
              id
            }
            twitter_logo {
              fluid {
                ...ImageFluid
              }
            }
            linkedin_logo {
              fluid {
                ...ImageFluid
              }
            }
            instagram_logo {
              fluid {
                ...ImageFluid
              }
            }
            facebook_logo {
              fluid {
                ...ImageFluid
              }
            }
          }
        }
      }
    }
  `)
