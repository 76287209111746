import { Container, makeStyles } from "@material-ui/core"

import classNames from "classnames"
import React from "react"
import { getColor, ThemeColor, useDefaultAppTheme } from "../../../root/common"
import { AppTheme } from "../../../root/common/context/theme/types"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { BackgroundData, HorizontalMargins } from "../../../types"

interface Props {
  className?: string
  containerClassName?: string
  children: React.ReactNode
  background?: BackgroundData
  backgroundMobile?: BackgroundData
  minHeight?: string
  flex?: boolean
  horizontalMargins?: HorizontalMargins
  noGutters?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    minHeight: ({ minHeight }: any) => minHeight,
    display: ({ flex }: any) => (flex ? "flex" : undefined),
    flexDirection: ({ flex }: any) => (flex ? "column" : undefined),

    "&::before": {
      content: ({ backgroundImage }: any) =>
        backgroundImage ? "' '" : undefined,
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      backgroundPosition: ({ backgroundImagePosition }: any) =>
        backgroundImagePosition ?? "center",
      backgroundSize: "cover",
      backgroundImage: ({ backgroundImage }: any) => `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      zIndex: -1,

      [whenDesktop(theme)]: {
        backgroundPosition: ({
          backgroundImagePositionX,
          backgroundImagePosition,
        }: any) =>
          `${backgroundImagePositionX ?? "center"} ${
            backgroundImagePosition ?? "center"
          }`,
        backgroundSize: ({ backgroundImageWidth }: any) =>
          backgroundImageWidth ?? "cover",
        backgroundAttachment: ({ backgroundFixed }: any) =>
          backgroundFixed ? "fixed" : undefined,
      },

      [whenMobile(theme)]: {
        backgroundImage: ({ backgroundImageMobile }: any) =>
          backgroundImageMobile ? `url(${backgroundImageMobile})` : undefined,
        backgroundPosition: ({ backgroundImagePositionMobile }: any) =>
          backgroundImagePositionMobile ?? "center",
      },
    },

    "&::after": {
      content: ({ backgroundColor }: any) =>
        backgroundColor ? "' '" : undefined,
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      backgroundColor: ({ backgroundColor }: any) => backgroundColor,
      zIndex: -2,
    },
  },
  container: {
    // [theme.breakpoints.up("md")]: {
    //   maxWidth: 960,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   maxWidth: 1280,
    // },
  },
  horizontalMarginsSm: {},
  horizontalMarginsXs: {},
  horizontalMarginsNone: {},
  noGutters: {
    maxWidth: "inherit",
  },
}))

const getBackgroundColor = (
  theme: AppTheme,
  color: ThemeColor | undefined,
  colorValue: string | undefined
) => {
  if (colorValue) {
    return colorValue
  }

  if (color) {
    return getColor(theme, color)
  }
}

const CustomContainer = ({
  children,
  background,
  backgroundMobile,
  className,
  containerClassName,
  minHeight,
  horizontalMargins,
  noGutters,
  flex,
}: Props) => {
  const theme = useDefaultAppTheme()
  const classes = useStyles({
    backgroundColor: getBackgroundColor(
      theme,
      background?.color,
      background?.colorValue
    ),
    backgroundImage: background?.image,
    backgroundImageWidth: background?.width,
    backgroundImagePosition: background?.position,
    backgroundImagePositionX: background?.positionX,
    backgroundFixed: background?.fixed,
    backgroundImageMobile: backgroundMobile?.image,
    backgroundImagePositionMobile: backgroundMobile?.position,
    backgroundColorMobile: getBackgroundColor(
      theme,
      backgroundMobile?.color,
      backgroundMobile?.colorValue
    ),
    backgroundMobileFixed: backgroundMobile?.fixed,
    minHeight,
    flex,
  })
  return (
    <div className={classNames(classes.root, className)}>
      <Container
        className={classNames(
          classes.container,
          {
            [classes.horizontalMarginsSm]: horizontalMargins === "sm",
            [classes.horizontalMarginsXs]: horizontalMargins === "xs",
            [classes.horizontalMarginsNone]: horizontalMargins === "none",
            [classes.noGutters]: noGutters === true,
          },
          containerClassName
        )}
      >
        {children as any}
      </Container>
    </div>
  )
}

export default CustomContainer
