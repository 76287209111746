import React from "react"
import { usePageLanguage } from "../../../root/common"
import { WebsiteFooter_allPrismicFooter_nodes_data as FooterData } from "../../../__generated__/WebsiteFooter"
import { useFooterDataQuery } from "./queries"

export const FooterDataContext = React.createContext<FooterData>(
  {} as FooterData
)

interface Props {
  children: any
}

export const FooterDataProvider = ({ children }: Props) => {
  const footerData = useFooterDataQuery()
  const lang = usePageLanguage()
  return (
    <FooterDataContext.Provider
      value={
        footerData.allPrismicFooter.nodes.find((x) => x.lang === lang)
          ?.data as FooterData
      }
    >
      {children}
    </FooterDataContext.Provider>
  )
}

export const useFooterData = () => React.useContext(FooterDataContext)
