import React from "react"
import { useHeaderData } from "../../../../features/data/header"
import { DesktopOnly, MobileOnly } from "../../../../root/ui/mui/visibility"
import HeaderBar from "./HeaderBar"
import HeaderDrawer from "./HeaderDrawer"
import { HeaderStyle } from "./types"

interface Props {
  style: HeaderStyle
}

const Header = ({ style }: Props) => {
  const headerData = useHeaderData()
  return (
    <>
      <DesktopOnly>
        <HeaderBar data={headerData} style={style} />
      </DesktopOnly>
      <MobileOnly>
        <HeaderDrawer data={headerData} style={style} />
      </MobileOnly>
    </>
  )
}

export default Header

export * from "./types"
