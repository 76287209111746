import React from "react"
import { usePageLanguage } from "../../../root/common"
import { WebsiteHeader_allPrismicHeader_nodes_data as HeaderData } from "../../../__generated__/WebsiteHeader"
import { useHeaderDataQuery } from "./queries"

export const HeaderDataContext = React.createContext<HeaderData>(
  {} as HeaderData
)

interface Props {
  children: any
}

export const HeaderDataProvider = ({ children }: Props) => {
  const headerData = useHeaderDataQuery()
  const lang = usePageLanguage()
  return (
    <HeaderDataContext.Provider
      value={
        headerData.allPrismicHeader.nodes.find((x) => x.lang === lang)
          ?.data as HeaderData
      }
    >
      {children}
    </HeaderDataContext.Provider>
  )
}

export const useHeaderData = () => React.useContext(HeaderDataContext)
