import { makeStyles } from "@material-ui/styles"
import React from "react"
import { FooterDataProvider } from "../../features/data/footer"
import { HeaderDataProvider } from "../../features/data/header"
import { CustomTypography } from "../../root/ui/mui"
import CookieBar from "./components/CookieBar"
import Footer, {
  FooterExtraContent,
  FooterLogo,
  FooterStyle,
  FooterText,
} from "./components/Footer"
import Header, { HeaderStyle } from "./components/Header"
import ScrollTopButton from "./components/ScrollTopButton"

interface Props {
  children: React.ReactChild
  headerStyle: HeaderStyle
  footerStyle: FooterStyle
  footerLogo: FooterLogo
  footerText: FooterText
  footerExtraContent?: FooterExtraContent
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {},
  content: {
    flex: 1,
  },
  footer: {
    position: "relative",
  },
}))

const DefaultLayout = ({
  children,
  headerStyle,
  footerStyle,
  footerLogo,
  footerText,
  footerExtraContent,
}: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header} id="header">
        <HeaderDataProvider>
          <Header style={headerStyle} />
        </HeaderDataProvider>
      </div>
      <main className={classes.content}>{children}</main>
      <div className={classes.footer}>
        <FooterDataProvider>
          <Footer
            style={footerStyle}
            logo={footerLogo}
            text={footerText}
            extraContent={footerExtraContent}
          />
        </FooterDataProvider>
      </div>
      <CookieBar
        cookieName="bloxCookiesAccepted"
        message={
          <CustomTypography>
            {
              "Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella cookie policy. Puoi acconsentire all'utilizzo di tali tecnologie chiudendo questa informativa"
            }
          </CustomTypography>
        }
        acceptButtonLabel="Accetta"
      />
      <ScrollTopButton />
    </div>
  )
}

DefaultLayout.defaultProps = {
  headerStyle: "white",
  footerStyle: "primary",
  footerLogo: "secondary",
  footerText: "white",
}

export default DefaultLayout
