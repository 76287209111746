import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import { LogosContext, WebsiteLogos } from "../../../../features/data"
import { useFooterData } from "../../../../features/data/footer"
import { LinkField, RichText, RichTextField } from "../../../../root/common"
import { isEmtyRichText, toLinkData } from "../../../../root/contents/prismic"
import {
  DesktopOnly,
  getMuiColor,
  MobileOnly,
  whenDesktop,
  whenMobile,
} from "../../../../root/ui/mui"
import CustomContainer from "../../../../ui/molecules/CustomContainer"

export type FooterStyle = "transparent" | "primary"
export type FooterLogo = "white" | "primary" | "secondary" | "none"
export type FooterText = "white" | "primary" | "secondary"

export interface FooterExtraContent {
  title: RichText
  content: RichText
}

interface Props {
  style: FooterStyle
  logo: FooterLogo
  text: FooterText
  extraContent?: FooterExtraContent
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    background: ({ backgroundColor }: any) =>
      backgroundColor ? getMuiColor(theme, backgroundColor) : undefined,
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,
  },
  transparent: {
    position: "absolute",
    bottom: 0,
    background: "transparent",
  },
  logoContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  },
  logo: {
    width: "100px",
    height: "31px",
  },
  footerLogo: {
    width: " 55px",
    padding: "0 20px",
  },
  link: {
    fontSize: "20px",
    letterSpacing: "0px",
    color: theme.palette.common.white,
    textDecoration: "none",
    fontWeight: "lighter",

    [whenDesktop(theme)]: {
      marginLeft: theme.spacing(6),
    },

    [whenMobile(theme)]: {
      paddingTop: theme.spacing(1),
    },
  },
  linksContainer: {
    display: "flex",
    justifyContent: "flex-end",

    [whenMobile(theme)]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  linkItem: {
    fontSize: "16px",
  },
  companyName: {
    fontWeight: 300,
  },
  companyInfo: {
    "& p": {
      fontWeight: 100,
    },
  },
  internalLinks: {
    textAlign: "right",
  },
  bottomHalf: {
    [whenMobile(theme)]: {
      marginTop: theme.spacing(4),
    },
  },
  dash: {
    width: "31px",
    height: "0px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: "10px",
    marginLeft: "7px",
    marginTop: "1px",

    [whenMobile(theme)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  copyrightContainer: {
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  copyrightContainerMobile: {},
  socialLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    [whenDesktop(theme)]: {
      justifyContent: "flex-end",
      paddingRight: theme.spacing(4),
    },
  },
  footerCopyright: {
    color: "#fff",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "14px",

    [whenMobile(theme)]: {
      justifyContent: "center",
    },
  },
  footerCopyrightYear: {
    fontSize: "14px",
    marginRight: theme.spacing(0.5),
    color: "#33CC99",
  },
  footerCopyrightText: {
    fontSize: "14px",
    color: "#33CC99",
  },
  extraContent: {
    textAlign: "center",
  },
  extraContentTitle: {
    marginTop: theme.spacing(4),
  },
  extraContentText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),

    "& p": {
      marginBottom: theme.spacing(4),
    },
  },
}))

// const SocialLinks = ({ classes, socialLinks }: any) => {
//   return (
//     <div className={classes.socialLinks}>
//       {socialLinks.map((link: any, index: number) => (
//         <a
//           href={link.url}
//           target="_blank"
//           rel="noopener noreferrer"
//           key={index}
//         >
//           <img className={classes.footerLogo} src={link.icon.fluid.base64} />
//         </a>
//       ))}
//     </div>
//   )
// }

const Copyright = ({ classes, label }: any) => {
  return (
    <div className={classes.footerCopyright}>
      <Typography className={classes.footerCopyrightYear}>
        © {new Date().getFullYear()}
      </Typography>
      <RichTextField value={label} className={classes.footerCopyrightText} />
    </div>
  )
}

const FooterLinks = ({ classes, linkItems }: any) => {
  return (
    <div className={classes.linksContainer}>
      {linkItems.map((item: any, index: number) => (
        <Box key={index} className={classes.link}>
          <LinkField value={item.link} className={classes.linkItem}>
            {item.label}
          </LinkField>
        </Box>
      ))}
    </div>
  )
}

const getLogo = (logos: WebsiteLogos, logo: FooterLogo) => {
  switch (logo) {
    case "primary":
      return logos.logoPrimary
    case "secondary":
      return logos.logoSecondary
    case "white":
      return logos.logoWhite
  }
}

const Footer = ({ style, logo, text, extraContent }: Props) => {
  const footerData = useFooterData()
  const logos = React.useContext(LogosContext)

  const classes = useStyles({
    backgroundColor: style === "primary" ? "primary" : undefined,
    textColor: text,
  })

  return (
    <div
      className={classNames(classes.root, {
        [classes.transparent]: style === "transparent",
      })}
    >
      <CustomContainer>
        {!isEmtyRichText(extraContent?.content) && (
          <div className={classes.extraContent}>
            <RichTextField
              value={extraContent?.title}
              variant="overline"
              color="secondary"
              className={classes.extraContentText}
            />
            <RichTextField
              value={extraContent?.content}
              className={classes.extraContentText}
            />
          </div>
        )}
        <Grid container>
          <Grid item xs={12} md={4} className={classes.logoContainer}>
            {logo !== "none" && (
              <img src={getLogo(logos, logo)} className={classes.logo} />
            )}
          </Grid>
          <Grid item xs={12} md={8} className={classes.internalLinks}>
            <FooterLinks
              classes={classes}
              linkItems={footerData.footer_links?.map((x) => ({
                link: x?.footer_link_target
                  ? toLinkData(x.footer_link_target as any)
                  : undefined,
                label: x?.footer_link_title?.text,
              }))}
            />
          </Grid>
          {/* <MobileOnly>
            <Grid item xs={12}>
              <Box mt={6} mb={1}>
                <SocialLinks classes={classes} socialLinks={social.links} />
              </Box>
            </Grid>
          </MobileOnly> */}
        </Grid>

        <div className={classes.bottomHalf}>
          <Grid container>
            <Grid item xs={12} md={3}>
              {/* <div className={classes.dash}>
                <Dash color={text} />
              </div> */}
              {/* <Typography variant="body2" className={classes.companyName}>
                {footerData.company_name?.text}
              </Typography>
              <RichTextField
                variant="body2"
                value={footerData.company_data}
                className={classes.companyInfo}
              /> */}
            </Grid>
            <Grid item xs={12} md={9}>
              <DesktopOnly>
                <div className={classes.copyrightContainer}>
                  {/* <SocialLinks classes={classes} socialLinks={social.links} /> */}
                  <Copyright
                    classes={classes}
                    label={footerData.footer_copyright}
                  />
                </div>
              </DesktopOnly>
              <MobileOnly>
                <div className={classes.copyrightContainerMobile}>
                  <Copyright
                    classes={classes}
                    label={footerData.footer_copyright}
                  />
                </div>
              </MobileOnly>
            </Grid>
          </Grid>
        </div>
      </CustomContainer>
    </div>
  )
}

export default Footer
