import { Fab, makeStyles, Theme } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { Link } from "gatsby"
import React from "react"
import { WebsiteHeader_allPrismicHeader_nodes_data as HeaderData } from "../../../../__generated__/WebsiteHeader"
import HeaderDrawerContent from "./HeaderDrawerContent"
import { useHeaderLogo } from "./hooks"
import { HeaderStyle } from "./types"

interface Props {
  data: HeaderData
  style: HeaderStyle
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    position: ({ inline }: any) => (!inline ? "absolute" : undefined),
    top: 0,
    left: 0,
    minHeight: theme.spacing(5),
    zIndex: 1,
    backgroundColor: ({ backgroundColor }: any) => backgroundColor,
  },
  logoContainer: {
    margin: theme.spacing(2),
  },
  logo: {
    width: 56,
  },
  menuFab: {
    margin: theme.spacing(1),
    backgroundColor: "transparent ! important",
    color: "white",
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  lang: {},
}))

const HeaderDrawer = ({ data, style }: Props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const classes = useStyles({
    inline: style !== "transparent",
    backgroundColor: style === "transparent" ? "transparent" : undefined,
    textColor: style === "transparent" ? "white" : "primary",
  })
  const logo = useHeaderLogo(style)
  return (
    <>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <Link to="/">
            <img src={logo} className={classes.logo} />
          </Link>
        </div>
        <div className={classes.menuContainer}>
          {/* <LanguageSwitch
            component={(lang) => (
              <CustomTypography
                variant="subtitle2"
                weight="lighter"
                color="white"
                className={classes.lang}
              >
                {lang}
              </CustomTypography>
            )}
          /> */}
          <Fab
            className={classes.menuFab}
            size="small"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon color={style === "white" ? "primary" : undefined} />
          </Fab>
        </div>
      </div>
      <HeaderDrawerContent
        data={data}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  )
}

export default HeaderDrawer
